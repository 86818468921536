<template>
  <div class="animated fadeIn">
    <div id="Dashboard">
      <div style="height: 48px;">
        <b-button
          size="sm"
          variant="primary"
          @click="
            () => {
              loadData(false);
            }
          "
        >
          <i class="icon-reload"></i> Обновить
        </b-button>
      </div>

      <Grid
        ref="requestsGrid"
        :items="gridData.members"
        :columns="gridData.columns"
        :classes="gridData.classes"
        :actions="gridData.actions"
        :index="gridData.index"
        :filter-key="gridData.searchQuery"
        :defaultSort="gridData.sortBy"
        :defaultSortOrder="-1"
        :isDataLoading="isPageLoading"
      ></Grid>

      <b-modal
        ref="userInfoModal"
        size="md"
        scrollable
        ok-only
        title="Данные о пользователе"
      >
        <div class="d-block text-center">
          <UserData
            v-if="typeof selectedUser.Id !== 'undefined'"
            :user="selectedUser"
          ></UserData>
        </div>
      </b-modal>

      <b-modal
        ref="memberInfoModal"
        size="lg"
        scrollable
        ok-only
        title="Данные о игроке"
      >
        <div class="d-block text-center">
          <MemberData
            v-if="typeof selectedMember.Id !== 'undefined'"
            ref="memberData"
            :member="selectedMember"
            :baseURL="baseURL"
            :images="selectedMemberImages()"
            :getRank="getRank"
          ></MemberData>
        </div>
      </b-modal>

      <b-modal
        ref="commentInfoModal"
        size="lg"
        scrollable
        ok-only
        title="Комментарий организатора"
      >
        <div class="d-block text-center">
          {{ selectedRequest.Comment }}
        </div>
      </b-modal>

      <b-modal
        ref="confirmModal"
        size="md"
        scrollable
        @ok="handleConfirm"
        :title="'Подтверждение заявки от ' + confirmModalTitle"
      >
        <div class="d-block" v-if="confirmModalTitle !== ''">
          <b-form-group
            id="confirmModalDiscountPrice"
            :description="discountUntilDesc"
            label="Взнос со временной скидкой"
            label-for="discountPrice"
          >
            <b-form-group label="">
              <b-form-radio-group
                id="confirm_discount_radios"
                v-model="confirmData.discountPrice"
                name="confirmDiscountRadios"
              >
                <b-form-radio :value="selectedRequest.Event.DiscountPriceUAH">{{
                  selectedRequest.Event.DiscountPriceUAH | formatCurrency
                }}</b-form-radio>
                <b-form-radio :value="0.0"
                  >{{ 0.0 | formatCurrency }} (бесплатно)</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <div class="input-group mb-1">
              <b-form-input
                id="discountPrice"
                type="number"
                step="100"
                min="0"
                v-model="confirmData.discountPrice"
                trim
              ></b-form-input>
              <div class="input-group-append">
                <span class="input-group-text">грн</span>
              </div>
            </div>
          </b-form-group>

          <b-form-group
            id="confirmModalDiscountPrice"
            :description="noDiscountUntilDesc"
            label="Взнос без временной скидки"
            label-for="discountPrice"
          >
            <b-form-group label="">
              <b-form-radio-group
                id="confirm_price_radios"
                v-model="confirmData.price"
                name="confirmPriceRadios"
              >
                <b-form-radio :value="selectedRequest.Event.PriceUAH">{{
                  selectedRequest.Event.PriceUAH | formatCurrency
                }}</b-form-radio>
                <b-form-radio :value="0.0"
                  >{{ 0.0 | formatCurrency }} (бесплатно)</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <div class="input-group mb-1">
              <b-form-input
                id="noDiscountPrice"
                type="number"
                step="100"
                min="0"
                v-model="confirmData.price"
                trim
              ></b-form-input>
              <div class="input-group-append">
                <span class="input-group-text">грн</span>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            id="confirmModalAfterRegPrice"
            :description="afterRegDesc"
            label="Взнос после закрытия регистрации"
            label-for="afterRegPrice"
          >
            <b-form-group label="">
              <b-form-radio-group
                id="confirm_after_reg_price_radios"
                v-model="confirmData.afterRegPrice"
                name="confirmAfterRegPriceRadios"
              >
                <b-form-radio
                  :value="selectedRequest.Event.AfterRegClosedPriceUAH"
                  >{{
                    selectedRequest.Event.AfterRegClosedPriceUAH
                      | formatCurrency
                  }}</b-form-radio
                >
                <b-form-radio :value="0.0"
                  >{{ 0.0 | formatCurrency }} (бесплатно)</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <div class="input-group mb-1">
              <b-form-input
                id="afterRegPrice"
                type="number"
                step="100"
                min="0"
                v-model="confirmData.afterRegPrice"
                trim
              ></b-form-input>
              <div class="input-group-append">
                <span class="input-group-text">грн</span>
              </div>
            </div>
          </b-form-group>
          <b-button v-b-toggle.confirmComment class="m-1"
            >Добавить комментарий</b-button
          >
          <b-collapse id="confirmComment">
            <b-form-group label="Комментарий" description="">
              <textarea
                class="form-control"
                id="confirmCommentText"
                name="confirmCommentText"
                style="min-height: 56px"
                rows="2"
                placeholder="Добавьте комментарий к письму с подтверждением заявки (необязательно)"
                v-model="confirmData.comment"
                trim
              >
              </textarea>
            </b-form-group>
          </b-collapse>
        </div>
      </b-modal>

      <b-modal
        ref="commentModal"
        size="md"
        scrollable
        @ok="handleComment"
        :title="'Комментарий к заявке от ' + confirmModalTitle"
      >
        <div class="d-block" v-if="confirmModalTitle !== ''">
          <b-form-group label="Комментарий" description="">
            <textarea
              class="form-control"
              id="commentCommentText"
              name="commentCommentText"
              style="min-height: 56px"
              rows="2"
              placeholder="Добавьте комментарий к письму"
              v-model="commentData.comment"
              trim
            >
            </textarea>
          </b-form-group>
        </div>
      </b-modal>

      <b-modal
        ref="rejectModal"
        size="md"
        scrollable
        @ok="handleReject"
        :title="'Отклонение заявки от ' + confirmModalTitle"
      >
        <div class="d-block" v-if="confirmModalTitle !== ''">
          <b-form-group label="Комментарий" description="">
            <textarea
              class="form-control"
              id="rejectCommentText"
              name="rejectCommentText"
              style="min-height: 56px"
              rows="2"
              placeholder="Добавьте комментарий к письму"
              v-model="rejectData.comment"
            >
            </textarea>
          </b-form-group>
        </div>
      </b-modal>

      <b-modal
        ref="payModal"
        size="md"
        scrollable
        @ok="handlePayed"
        :title="'Оплата регистрации от ' + confirmModalTitle"
      >
        <div class="d-block" v-if="confirmModalTitle !== ''">
          <b-form-group label="Взнос со временной скидкой" description="">
            <span>{{
              selectedRequest.DiscountAmountUAH | formatCurrency
            }}</span>
          </b-form-group>

          <b-form-group
            :description="noDiscountUntilDesc"
            label="Взнос без временной скидки"
          >
            <span>{{ selectedRequest.AmountUAH | formatCurrency }}</span>
          </b-form-group>

          <b-form-group
            :description="afterRegDesc"
            label="Взнос после закрытия регистрации"
          >
            <span>{{
              selectedRequest.AfterRegAmountUAH | formatCurrency
            }}</span>
          </b-form-group>

          <b-form-group
            label="Сколько оплатил?"
            label-for="payedAmount"
            description="(суммарно, если была оплата частями)"
          >
            <b-form-group label="">
              <b-form-radio-group
                id="payedAmount"
                name="payedAmountRadios"
                v-model="payedData.paidAmount"
              >
                <b-form-radio :value="selectedRequest.DiscountAmountUAH">{{
                  selectedRequest.DiscountAmountUAH | formatCurrency
                }}</b-form-radio>
                <b-form-radio :value="selectedRequest.AmountUAH">{{
                  selectedRequest.AmountUAH | formatCurrency
                }}</b-form-radio>
                <b-form-radio :value="selectedRequest.AfterRegAmountUAH">{{
                  selectedRequest.AfterRegAmountUAH | formatCurrency
                }}</b-form-radio>
                <b-form-radio :value="0.0"
                  >{{ 0.0 | formatCurrency }} (не платил)</b-form-radio
                >
              </b-form-radio-group>
            </b-form-group>
            <div class="input-group mb-1">
              <b-form-input
                id="noDiscountPrice"
                type="number"
                step="100"
                min="0"
                v-model="payedData.paidAmount"
                trim
              ></b-form-input>
              <div class="input-group-append">
                <span class="input-group-text">грн</span>
              </div>
            </div>
          </b-form-group>
          <b-form-group
            id="payModalPaymentType"
            label="Тип оплаты"
            label-for="discountPrice"
          >
            <b-form-group label="">
              <b-form-radio-group
                id="payment_type_radios"
                v-model="payedData.paymentType"
                name="paymentTypeRadios"
              >
                <b-form-radio :value="paymentTypeValues.Cashless">{{
                  paymentTypeVariants.get(paymentTypeValues.Cashless)
                }}</b-form-radio>
                <b-form-radio :value="paymentTypeValues.Cash">{{
                  paymentTypeVariants.get(paymentTypeValues.Cash)
                }}</b-form-radio>
                <b-form-radio :value="paymentTypeValues.Combatant">{{
                  paymentTypeVariants.get(paymentTypeValues.Combatant)
                }}</b-form-radio>
                <b-form-radio :value="paymentTypeValues.Free">{{
                  paymentTypeVariants.get(paymentTypeValues.Free)
                }}</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-form-group>

          <b-form-group
            label=""
            label-for="isFullPaymentForced"
            description="(включать в случае, если необходимо отметить полную оплату, когда не вся сумма была заплачена)"
          >
            <b-form-checkbox
              id="isFullPaymentForced"
              v-model="payedData.forceFullPayment"
              name="isFullPaymentForced"
              switch
            >
              Форсировать полный платеж
            </b-form-checkbox>
          </b-form-group>

          <b-form-group label="Комментарий" description="">
            <textarea
              class="form-control"
              id="payCommentText"
              name="payCommentText"
              style="min-height: 56px"
              rows="2"
              placeholder="Добавьте комментарий к письму"
              v-model="payedData.comment"
            >
            </textarea>
          </b-form-group>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import API from "../../api/stalk.net";
import Grid from "../../components/common/Grid";
import UserData from "../../components/UserData";
import MemberData from "../../components/MemberData";

export default {
  name: "Requests",
  components: {
    Grid,
    UserData,
    MemberData
  },
  data() {
    return {
      isPageLoading: false,
      gridData: {
        members: [],
        columns: [
          { title: "Id", path: "Id" },
          {
            title: "Позывной",
            path: "Member.Callsign",
            action: this.viewMemberDataByRequestId
          },
          { title: "Фамилия", path: "Member.User.LastName" },
          { title: "Имя", path: "Member.User.FirstName" },
          {
            title: "Email",
            path: "Member.User.Email",
            handler: str => {
              return Vue.filter("limitString")(str);
            },
            action: this.viewUserDataByRequestId
          },
          { title: "Группировка", path: "Member.Fraction.Title" },
          { title: "Город", path: "Member.User.City.Name" },
          // {
          //   title: "ПДА?",
          //   path: "Pda",
          //   handler: str => {
          //     return API.getPdaVariantTitle(str);
          //   }
          // },
          {
            title: "Паспорт?",
            path: "HasPassport",
            handler: str => {
              return API.getCommonYesNo(str);
            }
          },
          {
            title: "Взнос",
            path: "AmountUAH",
            handler: str => {
              return Vue.filter("formatCurrency")(parseFloat(str));
            }
          },
          {
            title: "Со скидкой",
            path: "DiscountAmountUAH",
            handler: str => {
              return Vue.filter("formatCurrency")(parseFloat(str));
            }
          },
          {
            title: "Дата заявки",
            path: "CreatedAt",
            handler: str => {
              return Vue.filter("formatDate")(str);
            }
          },
          {
            title: "Изменен",
            path: "Member.UpdatedAt",
            handler: str => {
              return Vue.filter("formatDateTime")(str);
            }
          },
          {
            title: "Квента ОК?",
            path: "IsLegendOK",
            handler: str => {
              return API.getCommonYesNo(str);
            }
          },
          {
            title: '"Антураж" ОК?',
            path: "IsLookOK",
            handler: str => {
              return API.getCommonYesNo(str);
            }
          },
          {
            title: "Статус",
            path: "Confirmation",
            handler: str => {
              return API.getConfirmTitle(str);
            },
            action: this.viewCommentByRequestId
          }
        ],
        index: { title: { path: "Member.Callsign" }, id: { path: "Id" } },
        classes: [
          {
            path: "Member.User.Group",
            equals: API.consts.UserGroups.GameMaster,
            type: "table-green"
          },
          {
            path: "Member.User.Group",
            equals: API.consts.UserGroups.Admin,
            type: "table-green"
          },
          {
            path: "Confirmation",
            equals: API.consts.Request.Approved,
            type: "table-blue"
          },
          {
            path: "Confirmation",
            equals: API.consts.Request.Commented,
            type: "table-yellow"
          },
          {
            path: "Confirmation",
            equals: API.consts.Request.Declined,
            type: "table-red"
          }
        ],
        actions: [
          {
            name: "request_look_approve",
            title: 'Одобрить "антураж"',
            disabled: !this.isActionAvailable("request_look_approve"),
            filter: this.isCanLookApprove,
            action: this.confirmLook
          },
          {
            name: "request_legend_approve",
            title: "Одобрить квенту",
            disabled: !this.isActionAvailable("request_legend_approve"),
            filter: this.isCanLegendApprove,
            action: this.confirmLegend
          },
          {
            name: "request_approve",
            title: "Подтвердить",
            disabled: !this.isActionAvailable("request_approve"),
            filter: this.isCanApprove,
            action: this.confirm
          },
          {
            name: "request_comment",
            title: "Комментировать",
            disabled: !this.isActionAvailable("request_comment"),
            filter: this.isCanComment,
            action: this.comment
          },
          {
            name: "request_reject",
            title: "Отклонить",
            disabled: !this.isActionAvailable("request_reject"),
            filter: this.isCanReject,
            action: this.reject
          },
          {
            name: "request_payed",
            title: "Оплатил",
            disabled: !this.isActionAvailable("request_payed"),
            filter: this.isCanPayed,
            action: this.payed
          }
        ],
        searchQuery: "",
        sortBy: {}
      },
      selectedUser: {},
      selectedMember: {},
      selectedRequest: {},
      baseURL: API.baseURL,

      confirmData: {
        discountPrice: 0.0,
        price: 0.0,
        afterRegPrice: 0.0,
        comment: ""
      },
      commentData: {
        comment: ""
      },
      rejectData: {
        comment: ""
      },
      paymentTypeVariants: API.paymentTypeVariants,
      paymentTypeValues: API.consts.PaymentType,
      payedData: {
        comment: "",
        paidAmount: 0.0,
        paymentType: API.consts.PaymentType.Cashless,
        forceFullPayment: false
      }
    };
  },
  methods: {
    loadData(sort = true) {
      let self = this;
      self.isPageLoading = true;
      API.private
        .getEventRequests()
        .then(resp => {
          self.gridData.members = resp.data.map(req => {
            req.DiscountAmountUAH = parseFloat(req.DiscountAmountUAH);
            req.AmountUAH = parseFloat(req.AmountUAH);
            req.AfterRegAmountUAH = parseFloat(req.AfterRegAmountUAH);
            if (req.Event) {
              req.Event.DiscountPriceUAH = parseFloat(
                req.Event.DiscountPriceUAH
              );
              req.Event.PriceUAH = parseFloat(req.Event.PriceUAH);
              req.Event.AfterRegClosedPriceUAH = parseFloat(
                req.Event.AfterRegClosedPriceUAH
              );
              req.Event.DiscountVariants = JSON.parse(
                req.Event.DiscountVariants
              );
            }
            return req;
          });
          if (sort) {
            self.gridData.sortby = { title: "Id", path: "Id" };
            self.$refs.requestsGrid.sortBy(self.gridData.sortby);
          }
          self.isPageLoading = false;
        })
        .then(() => {
          self.$forceUpdate();
        })
        .catch(self.catchError);
    },
    viewUserDataByRequestId(rid) {
      let self = this;

      API.private
        .getUserByRequest(rid)
        .then(resp => {
          self.selectedUser = resp.data;
          self.$refs.userInfoModal.show();
        })
        .catch(self.catchError);
    },
    viewMemberDataByRequestId(rid) {
      let self = this;
      API.private
        .getMemberByRequest(rid)
        .then(resp => {
          self.selectedMember = resp.data;
          self.$refs.memberInfoModal.show();
        })
        .catch(self.catchError);
    },
    viewCommentByRequestId(rid) {
      let self = this;

      API.private
        .getRequestById(rid)
        .then(resp => {
          self.selectedRequest = resp.data;
          if (self.selectedRequest && self.selectedRequest.Comment.length > 0) {
            self.$refs.commentInfoModal.show();
          }
        })
        .catch(self.catchError);
    },
    catchError(e) {
      let self = this;

      console.error(e);
      if (e.response && e.response.data && e.response.data.error) {
        console.error(e.response.data);
        switch (e.response.data.error) {
          case "ERR_BAD_JWT":
          case "ERR_BAD_AUTH":
          case "ERR_NOT_ALLOWED":
            {
              API.private.logout();
              self.$router.replace("/login");
            }
            break;
        }
        self.showError({ message: e.response });
      }
    },
    getRank(rank) {
      return API.getRankTitle(rank);
    },
    selectedMemberImages() {
      let self = this;
      let images = [];
      self.selectedMember.Photos.forEach(photo => {
        images.push(self.baseURL + photo.Path);
      });

      return images;
    },
    isActionAvailable(name) {
      let requests = localStorage.getItem("actions");
      return requests.includes(name);
    },
    isCanLookApprove(obj) {
      return (
        (obj.Confirmation === API.consts.Request.None ||
          obj.Confirmation === API.consts.Request.Commented) &&
        !obj.IsLookOK
      );
    },
    isCanLegendApprove(obj) {
      return (
        (obj.Confirmation === API.consts.Request.None ||
          obj.Confirmation === API.consts.Request.Commented) &&
        !obj.IsLegendOK
      );
    },
    isCanApprove(obj) {
      return (
        obj.Confirmation === API.consts.Request.None ||
        obj.Confirmation === API.consts.Request.Commented
      );
    },
    isCanComment(obj) {
      return (
        obj.Confirmation === API.consts.Request.None ||
        obj.Confirmation === API.consts.Request.Commented
      );
    },
    isCanReject(obj) {
      return (
        obj.Confirmation === API.consts.Request.None ||
        obj.Confirmation === API.consts.Request.Commented
      );
    },
    isCanPayed(obj) {
      return (
        obj.Confirmation === API.consts.Request.Approved ||
        obj.Confirmation === API.consts.Request.PartiallyPaid
      );
    },
    confirmLook(request) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .confirmLookForRequest(request.Id)
        .then(() => {
          self.isPageLoading = false;
          return self.loadData();
        })
        .catch(self.catchError);
    },
    confirmLegend(request) {
      let self = this;

      self.isPageLoading = true;
      API.private
        .confirmLegendForRequest(request.Id)
        .then(() => {
          self.isPageLoading = false;
          return self.loadData();
        })
        .catch(self.catchError);
    },
    confirm(request) {
      this.selectedRequest = request;
      this.confirmData.discountPrice = request.Event.DiscountPriceUAH;
      this.confirmData.price = request.Event.PriceUAH;
      this.confirmData.afterRegPrice = request.Event.AfterRegClosedPriceUAH;
      this.$forceUpdate();
      this.$refs.confirmModal.show();
    },
    comment(request) {
      this.selectedRequest = request;
      this.$forceUpdate();
      this.$refs.commentModal.show();
    },
    reject(request) {
      this.selectedRequest = request;
      this.$forceUpdate();
      this.$refs.rejectModal.show();
    },
    payed(request) {
      this.selectedRequest = request;
      this.payedData.paidAmount = request.AmountUAH;
      this.$forceUpdate();
      this.$refs.payModal.show();
    },

    handleConfirm() {
      let self = this;
      this.$refs.confirmModal.hide();
      self.isPageLoading = true;
      API.private
        .approveRequest(self.selectedRequest.Id, self.confirmData)
        .then(() => {
          self.isPageLoading = false;
          return self.loadData();
        })
        .catch(self.catchError);
    },
    handleComment() {
      let self = this;
      this.$refs.commentModal.hide();
      self.isPageLoading = true;
      API.private
        .commentRequest(self.selectedRequest.Id, self.commentData)
        .then(() => {
          self.isPageLoading = false;
          return self.loadData();
        })
        .catch(self.catchError);
    },
    handleReject() {
      let self = this;
      this.$refs.rejectModal.hide();
      self.isPageLoading = true;
      API.private
        .rejectRequest(self.selectedRequest.Id, self.rejectData)
        .then(() => {
          self.isPageLoading = false;
          return self.loadData();
        })
        .catch(self.catchError);
    },
    handlePayed() {
      let self = this;
      console.log(self.payedData);
      this.$refs.payModal.hide();
      self.isPageLoading = true;
      API.private
        .payedRequest(self.selectedRequest.Id, self.payedData)
        .then(resp => {
          console.log(resp);
          self.isPageLoading = false;
          return self.loadData();
        })
        .catch(self.catchError);
    }
  },
  computed: {
    confirmModalTitle() {
      if (typeof this.selectedRequest.Id === "undefined") {
        return "";
      }
      return (
        this.selectedRequest.Member.Callsign +
        " (" +
        this.selectedRequest.Member.Fraction.Title +
        ")"
      );
    },
    discountUntilDesc() {
      return (
        "* сколько игрок должен заплатить до " +
        Vue.filter("formatDate")(this.selectedRequest.Event.DiscountUntil)
      );
    },
    noDiscountUntilDesc() {
      return (
        "* сколько игрок должен заплатить после " +
        Vue.filter("formatDate")(this.selectedRequest.Event.DiscountUntil)
      );
    },
    afterRegDesc() {
      return (
        "* сколько игрок должен заплатить после " +
        Vue.filter("formatDate")(this.selectedRequest.Event.RegistrationUntil) +
        " (закрытия регистрации)"
      );
    }
  },
  beforeMount: function() {
    this.loadData();
  },
  notifications: {
    showError: {
      title: "Ошибка",
      type: "error"
    }
  }
};
</script>
